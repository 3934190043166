<template>
  <div
    style="
      background-image: url(/media/anjungan/BG-1280x1024.jpg);
      width: 100%;
      background-size: cover;
    "
  >
    <!-- Heder::Start -->
    <!-- <div class="my-card-header"></div> -->
    <div class="header" style="background-image: url(/media/anjungan/Header.png)"></div>
    <!-- Heder::End -->
    <!-- Menu::Start -->
    <div class="container">
      <div class="row">
        <div class="col-4">
          <img src="/media/anjungan/icon_Layanan-Mandiri.png" alt="" />
        </div>
        <div class="col-4">
          <img src="/media/anjungan/icon_Profil-Desa.png" alt="" />
        </div>
        <div class="col-4">
          <img src="/media/anjungan/icon_Kegiatan-Desa.png" alt="" />
        </div>
        <div class="col-4">
          <img src="/media/anjungan/icon_Statistik-Desa.png" alt="" />
        </div>
        <div class="col-4">
          <img src="/media/anjungan/icon_Peta-Desa.png" alt="" />
        </div>
        <div class="col-4">
          <img src="/media/anjungan/icon_Kontak.png" alt="" />
        </div>
      </div>
    </div>
    <!-- Menu::End -->
    <!-- Heder::Start -->
    <div
      class="footer"
      style="background-image: url(/media/anjungan/Footer.png)"
    ></div>
    <!-- Heder::End -->
  </div>
</template>

<script>
export default {};
</script>

<style>
.my-card-header {
  height: 324px;
  background: white;
  border-radius: 50% 50% 50% 50% / 34% 36% 28% 28%;
  position: relative;
  top: -111px;
  box-shadow: -1px 11px 15px -1px rgba(176,105,105,0.65);
-webkit-box-shadow: -1px 11px 15px -1px rgba(176,105,105,0.65);
-moz-box-shadow: -1px 11px 15px -1px rgba(176,105,105,0.65);
}

.header {
  width: 1280px;
  height: 282px;
}

.footer {
  width: 1280px;
  height: 282px;
  position: relative;
  bottom: -68px;
}
</style>